@mixin shadowCloud($background, $top: null, $right: null, $bottom: null, $left: null, $width: 370px, $height: 153px, $blur: 350px) {
  content: "";
  width: $width;
  height: $height;
  transform: rotate(-90deg);
  flex-shrink: 0;
  border-radius: 370px;
  background: $background;
  filter: blur($blur);
  position: absolute;
  top: $top;
  right: $right;
  left: $left;
  bottom: $bottom;
}
