
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
.fixed-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: $black;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  -webkit-animation: seconds 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 1s;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
  pointer-events: none;

  @-webkit-keyframes seconds {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes seconds {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  &.active {
    opacity: 0;
    pointer-events: none;
  }
  span {
    color: $white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: $lg) {
      font-size: 1rem;
    }
  }
  &-shadow {
    width: 100%;
    position: absolute;
    height: 100vh;
    display: flex;
    &-left {
      position: relative;
      width: 50%;
      height: 100%;
      &:after {
        @include shadowCloud($blueShadow, 20%, auto, auto, -140px);
      }
      &:before {
        @include shadowCloud($greenShadow, 70%, auto, auto, -140px);
      }
      @media screen and (max-width: $lg) {
        &:after {
          @include shadowCloud($blueShadow, 20%, auto, auto, -100px, 250px, 120px, 50px);

        }
        &:before {
          @include shadowCloud($greenShadow, 70%, auto, auto, -100px, 250px, 120px, 50px);
        }
      }
    }
    &-right {
      position: relative;
      width: 50%;
      height: 100%;
      &:after {
        @include shadowCloud($blueShadow, 20%, -140px, auto, auto, 300px);
      }
      &:before {
        @include shadowCloud($greenShadow, 70%, -140px, auto, auto, 300px);
      }
      @media screen and (max-width: $lg) {
        &:after {
          @include shadowCloud($blueShadow, 20%, -100px, auto, auto, 250px, 120px, 50px);

        }
        &:before {
          @include shadowCloud($greenShadow, 70%, -100px, auto, auto, 250px, 120px, 50px);
        }
      }
    }
  }
  .counter {
    width: 100px;
    font: 800 40px system-ui;
    overflow: hidden;
  }
  .numbers {
    width: auto;
    display: flex;
    animation: countNumber 1.5s;
    animation-timing-function: steps(100);
  }
  .numbers span {
    text-align: center;
    flex: 0 0 100px;
  }

  @keyframes countNumber {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: -10000px;
    }
  }
}

